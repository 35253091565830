import React from "react";

const KanbanProspects = ({ prospects }) => {
  // Définir les colonnes du Kanban et leurs couleurs de fond
  const kanbanColumns = [
    { key: "Prospects", bgColor: "bg-white" },
    { key: "Qualifiés", bgColor: "bg-yellow-200" },
    { key: "Clients", bgColor: "bg-green-200" },
    { key: "Perdus", bgColor: "bg-gray-300" },
  ];

  // Couleurs des statuts
  const statusColors = {
    "Nouveau Prospect": "bg-purple-600",
    "Contacté & en attente": "bg-blue-600",
    "Prospect Qualifié": "bg-yellow-600",
    "En Test": "bg-orange-600",
    "Client": "bg-green-600",
    "Perdu": "bg-gray-600",
  };

  // Regrouper les prospects en fonction de la colonne Kanban
  const groupedProspects = kanbanColumns.reduce((acc, column) => {
    acc[column.key] = [];
    return acc;
  }, {});

  prospects.forEach((prospect) => {
    const columnKey =
      prospect.statut === "Client"
        ? "Clients"
        : prospect.statut === "Perdu"
        ? "Perdus"
        : ["Nouveau Prospect", "Contacté & en attente"].includes(prospect.statut)
        ? "Prospects"
        : "Qualifiés";
    groupedProspects[columnKey].push(prospect);
  });

  return (
    <div className="flex gap-4 overflow-x-auto">
      {kanbanColumns.map(({ key, bgColor }) => (
        <div key={key} className={`${bgColor} rounded-lg p-4 w-72`}>
          <h2 className="text-lg font-bold text-gray-800 mb-4">{key}</h2>
          <div className="space-y-4">
            {groupedProspects[key]?.map((prospect) => (
              <div
                key={prospect.id}
                className="bg-gray-700 rounded-lg p-4 shadow-md"
              >
                <h3 className="text-white text-sm font-bold">
                  {prospect.club || "N/A"}
                </h3>
                <h3 className="text-white text-xs">
                  {prospect.nom} {prospect.prenom}
                </h3>
                <p className="text-gray-400 text-xs">{prospect.provenance}</p>
                <span
                  className={`inline-block mt-2 px-2 py-1 text-xs rounded text-white ${statusColors[prospect.statut]}`}
                >
                  {prospect.statut}
                </span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default KanbanProspects;
