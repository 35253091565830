import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify"; // Pour assainir le texte enrichi
import {
  getAllRequests,
  updateRequest,
} from "./../../../../services/requestsServices";

const RequestsTable = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedScreenshot, setSelectedScreenshot] = useState(null);

  useEffect(() => {
    const fetchRequests = async () => {
      setLoading(true); // Active l'état de chargement avant de commencer
      try {
        const data = await getAllRequests();

        // Vérifie si les données sont valides et les met à jour
        if (Array.isArray(data) && data.length > 0) {
          setRequests(data);
          console.log("Données récupérées :", data);
        } else {
          console.warn("Aucune donnée reçue ou données invalides :", data);
          setRequests([]); // Définit un tableau vide si les données sont invalides
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des requêtes :", error);
        setRequests([]); // Définit un tableau vide en cas d'erreur
      } finally {
        setLoading(false); // Désactive l'état de chargement
      }
    };

    fetchRequests();
  }, []);

  const handleScreenshotClick = (url) => {
    setSelectedScreenshot(url);
  };

  const closeModal = () => {
    setSelectedScreenshot(null);
  };

  const handleStatusChange = async (requestId, currentStatus) => {
    // Définir le prochain statut en fonction de l'état actuel
    const nextStatus =
      currentStatus === "Pending"
        ? "In Progress"
        : currentStatus === "In Progress"
        ? "Completed"
        : "Pending";

    try {
      await updateRequest(requestId, { status: nextStatus });
      // Mettre à jour le statut localement après modification
      setRequests((prevRequests) =>
        prevRequests.map((req) =>
          req.id === requestId ? { ...req, status: nextStatus } : req
        )
      );
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut :", error);
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold mb-4">Résumé des Requêtes</h2>
      </div>
      {loading ? (
        <p>Chargement des données...</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="bg-gray-100 text-left">
                <th className="py-3 px-6">Association</th>
                <th className="py-3 px-6">Titre</th>
                <th className="py-3 px-6">Description</th>
                <th className="py-3 px-6">Statut</th>
                <th className="py-3 px-6">Priorité</th>
                <th className="py-3 px-6">Créé le</th>
                <th className="py-3 px-6">Capture</th>
              </tr>
            </thead>
            <tbody>
              {requests.map((request, index) => (
                <tr
                  key={request.id}
                  className={`border-b ${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  }`}
                >
                  <td className="py-3 px-6 flex items-center space-x-4">
                    <img
                      src={request.Associations.logo_asso}
                      alt={request.Associations.nom_asso}
                      className="w-10 h-10 rounded-full"
                    />
                    <span>{request.Associations.nom_asso}</span>
                  </td>
                  <td className="py-3 px-6">{request.title}</td>
                  <td
                    className="py-3 px-6"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(request.description),
                    }}
                  ></td>
                  <td className="py-3 px-6">
                    <span
                      onClick={() =>
                        handleStatusChange(request.id, request.status)
                      }
                      className={`cursor-pointer px-3 py-1 rounded text-white text-sm ${
                        request.status === "Pending"
                          ? "bg-yellow-500"
                          : request.status === "In Progress"
                          ? "bg-blue-500"
                          : "bg-green-500"
                      }`}
                    >
                      {request.status}
                    </span>
                  </td>
                  <td className="py-3 px-6">
                    <span
                      className={`px-3 py-1 rounded text-white text-sm ${
                        request.priority === "Low"
                          ? "bg-green-400"
                          : request.priority === "Medium"
                          ? "bg-yellow-500"
                          : "bg-red-500"
                      }`}
                    >
                      {request.priority}
                    </span>
                  </td>
                  <td className="py-3 px-6">
                    {new Date(request.createdAt).toLocaleDateString()}
                  </td>
                  <td className="py-3 px-6">
                    {request.screenshotUrl && (
                      <button
                        className="text-blue-500 underline"
                        onClick={() =>
                          handleScreenshotClick(request.screenshotUrl)
                        }
                      >
                        Voir
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {selectedScreenshot && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          onClick={closeModal}
        >
          <div
            className="bg-white p-4 rounded-lg max-w-4xl w-full relative"
            onClick={(e) => e.stopPropagation()} // Empêche la fermeture en cliquant sur l'image
          >
            <img
              src={selectedScreenshot}
              alt="Capture d'écran"
              className="w-full h-auto cursor-pointer"
              onClick={closeModal} // Ferme la modale au clic sur l'image
            />
            <button
              className="absolute top-2 right-2 text-red-500 font-bold text-lg"
              onClick={closeModal}
            >
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestsTable;
