import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "./../../../../hooks/Modal"; // Assurez-vous d'avoir un composant Modal réutilisable

const clubs = [
  {
    name: "MonAppliClub",
    appUrl: "https://www.monappliclub.com",
    apiUrl: "https://api.monappliclub.com",
    logo: "https://res.cloudinary.com/dy5kblr32/image/upload/v1724144796/settings/0148fa61b07902a69a3d43159ec4f54b558fac8c.png1724144795445.png",
    dataPaths: {
      visits: "/api/visites/countVisitesByDate",
      totalUsers: "/api/users/countUsers",
      todayUsers: "/api/users/countUsersToday",
      notifications: "/api/notifications/1",
    },
  },
  {
    name: "MonAppliClub Démo",
    appUrl: "https://www.monappliclub-demo.com",
    apiUrl: "https://api.monappliclub-demo.com",
    logo: "https://res.cloudinary.com/dy5kblr32/image/upload/v1724144796/settings/0148fa61b07902a69a3d43159ec4f54b558fac8c.png1724144795445.png",
    dataPaths: {
      visits: "/api/visites/countVisitesByDate",
      totalUsers: "/api/users/countUsers",
      todayUsers: "/api/users/countUsersToday",
      notifications: "/api/notifications/1",
    },
  },
  {
    name: "MonAppliClub Light",
    appUrl: "https://www.monappliclub-demo-light.com",
    apiUrl: "https://api.monappliclub-demo-light.com",
    logo: "https://res.cloudinary.com/dy5kblr32/image/upload/v1727959993/fake/logo_monappliclubdemolight_g5yjwc.png",
    dataPaths: {
      visits: "/api/visites/countVisitesByDate",
      notifications: "/api/notifications/1",
    },
  },
  {
    name: "Judo Coach Pro",
    appUrl: "https://www.judo-coach-pro.com",
    apiUrl: "https://api.judo-coach-pro.com",
    logo: "https://www.judo-coach-pro.com/logo_judocoachpro.png",
    dataPaths: {
      visits: "/api/visites/countVisitesByDate",
      totalUsers: "/api/users/countUsers",
      todayUsers: "/api/users/countUsersToday",
      notifications: "/api/notifications/1",
    },
  },
  {
    name: "USB Judo",
    appUrl: "https://www.usbouscat-judo.com",
    apiUrl: "https://api.usbouscat-judo.com",
    logo: "https://res.cloudinary.com/dy5kblr32/image/upload/v1715898537/images/settings/logo_club_zm3ybt.png",
    dataPaths: {
      visits: "/api/visites/countVisitesByDate",
      totalUsers: "/api/users/countUsers",
      todayUsers: "/api/users/countUsersToday",
      nombreAdherents: "/api/dashboard/comptageStatutLicencie",
      notifications: "/api/notifications/1",
    },
  },
  {
    name: "USB Karaté",
    appUrl: "https://www.usbouscat-karate.com",
    apiUrl: "https://api.usbouscat-karate.com",
    logo: "https://res.cloudinary.com/dy5kblr32/image/upload/v1724876971/fake/join_hxza6c.png",
    dataPaths: {
      visits: "/api/visites/countVisitesByDate",
      totalUsers: "/api/users/countUsers",
      todayUsers: "/api/users/countUsersToday",
      nombreAdherents: "/api/dashboard/comptageStatutLicencie",
      notifications: "/api/notifications/1",
    },
  },
  {
    name: "ESSHA Judo Segré",
    appUrl: "https://www.essha-judo-segre.com/",
    apiUrl: "https://api.essha-judo-segre.com",
    logo: "https://res.cloudinary.com/dj0c0zuje/image/upload/v1732802429/logo_essha_judo_segre_l2p9kz.png",
    dataPaths: {
      visits: "/api/visites/countVisitesByDate",
      totalUsers: "/api/users/countUsers",
      todayUsers: "/api/users/countUsersToday",
      nombreAdherents: "/api/dashboard/comptageStatutLicencie",
      notifications: "/api/notifications/1",
    },
  },
  {
    name: "Dojo Gujanais",
    appUrl: "https://www.dojogujanais.fr",
    apiUrl: "https://api.dojogujanais.fr",
    logo: "https://res.cloudinary.com/dj0c0zuje/image/upload/v1733150509/logo_dojo_gujanais_qayonu.jpg",
    dataPaths: {
      visits: "/api/visites/countVisitesByDate",
      totalUsers: "/api/users/countUsers",
      todayUsers: "/api/users/countUsersToday",
      nombreAdherents: "/api/dashboard/comptageStatutLicencie",
      notifications: "/api/notifications/1",
    },
  },
  {
    name: "JC Salon",
    appUrl: "https://www.judoclubsalon.fr",
    apiUrl: "https://api.judoclubsalon.fr",
    logo: "https://res.cloudinary.com/dj0c0zuje/image/upload/v1730982132/JUDOCLUBSALON/settings/Original.png1730982131933.png",
    dataPaths: {
      visits: "/api/visites/countVisitesByDate",
      totalUsers: "/api/users/countUsers",
      todayUsers: "/api/users/countUsersToday",
      nombreAdherents: "/api/dashboard/comptageStatutLicencie",
      notifications: "/api/notifications/1",
    },
  },
  {
    name: "Tanguy Sensei",
    appUrl: "https://www.tanguy-sensei.com",
    apiUrl: "https://api.tanguy-sensei.com",
    logo: "https://res.cloudinary.com/dj0c0zuje/image/upload/v1736435060/logo_tanguy_sensei_wvpvtv.png",
    dataPaths: {
      visits: "/api/visites/countVisitesByDate",
    },
  },

  // Ajoutez d'autres clubs ici avec leurs chemins spécifiques
];

const ClubSummaryTable = () => {
  const [clubData, setClubData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchClubDetails = async (club, today) => {
    const clubDetails = {
      name: club.name,
      logo: club.logo,
      appUrl: club.appUrl,
    };

    try {
      // Parcourir chaque clé et récupérer les données associées
      for (const [key, path] of Object.entries(club.dataPaths)) {
        const response = await axios.get(`${club.apiUrl}${path}`);
        switch (key) {
          case "visits":
            // Gestion spécifique pour les visites
            clubDetails[key] =
              response.data.find((visit) => visit.date_visite === today)
                ?.compteur || 0;
            break;

          case "notifications":
            // Gestion spécifique pour les notifications
            clubDetails[key] = response.data.filter((notification) =>
              notification.created_at.startsWith(today)
            );
            break;

          case "nombreAdherents":
            // Gestion spécifique pour les adhérents
            clubDetails[key] = response.data.nombreAdherents || 0;
            break;

          default:
            // Gestion générique pour les autres données
            clubDetails[key] = response.data.count || 0;
            break;
        }
      }
    } catch (error) {
      console.error(`Erreur pour ${club.name}:`, error.message);
      clubDetails.error = "Erreur lors de la récupération des données";
    }

    return clubDetails;
  };

  const fetchAllClubsData = async () => {
    setLoading(true);
    const today = new Date().toISOString().split("T")[0];
    const results = await Promise.all(
      clubs.map((club) => fetchClubDetails(club, today))
    );
    setClubData(results);
    setLoading(false);
  };

  useEffect(() => {
    fetchAllClubsData();
  }, []);

  const formatDateTime = (dateTime) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Intl.DateTimeFormat("fr-FR", options).format(new Date(dateTime));
  };

  const handleViewNotifications = (notifications) => {
    setSelectedNotifications(notifications);
    setIsModalOpen(true);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold mb-4">Résumé des Clubs</h2>
        <button
          onClick={fetchAllClubsData}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
        >
          Rafraîchir
        </button>
      </div>
      {loading ? (
        <p>Chargement des données...</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="bg-gray-100 text-left">
                <th className="py-3 px-6">Club</th>
                <th className="py-3 px-6">Visites du jour</th>
                <th className="py-3 px-6">Utilisateurs totaux</th>
                <th className="py-3 px-6">Utilisateurs aujourd'hui</th>
                <th className="py-3 px-6">Adhérents</th>
                <th className="py-3 px-6">Notifications</th>
              </tr>
            </thead>
            <tbody>
              {clubData.map((club, index) => (
                <tr
                  key={index}
                  className={`border-b ${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  }`}
                >
                  <td className="py-3 px-6 flex items-center space-x-4">
                    <img
                      src={club.logo}
                      alt={club.name}
                      className="w-10 h-10 rounded-full"
                    />
                    <a
                      href={club.appUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 underline"
                    >
                      {club.name}
                    </a>
                  </td>
                  <td className="py-3 px-6">
                    {club.visits !== undefined
                      ? club.visits
                      : club.error || ""}
                  </td>
                  <td className="py-3 px-6">
                    {club.totalUsers !== undefined
                      ? club.totalUsers
                      : club.error || ""}
                  </td>
                  <td className="py-3 px-6">
                    {club.todayUsers !== undefined ? (
                      <span
                        className={`px-3 py-1 rounded text-white text-sm ${
                          club.todayUsers > 0 ? "bg-red-500" : "bg-gray-400"
                        }`}
                      >
                        {club.todayUsers}
                      </span>
                    ) : (
                      club.error || ""
                    )}
                  </td>

                  <td className="py-3 px-6">
                    {club.nombreAdherents !== undefined
                      ? club.nombreAdherents
                      : club.error || ""}
                  </td>
                  <td className="py-3 px-6">
                    <button
                      className={`px-3 py-1 rounded text-white text-sm ${
                        club.notifications?.length > 0
                          ? "bg-blue-500 hover:bg-blue-600"
                          : "bg-gray-400"
                      }`}
                      disabled={club.notifications?.length === 0}
                      onClick={() =>
                        handleViewNotifications(club.notifications)
                      }
                    >
                      {club.notifications?.length || 0}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isModalOpen && (
        <Modal
          onClose={() => setIsModalOpen(false)}
          title="Notifications du Jour"
        >
          <table className="min-w-full bg-gray-200 text-gray-800">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b border-gray-400">Message</th>
                <th className="py-2 px-4 border-b border-gray-400">Date</th>
              </tr>
            </thead>
            <tbody>
              {selectedNotifications.map((notification, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 border-b border-gray-400">
                    {notification.message}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-400">
                    {formatDateTime(notification.created_at)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
      )}
    </div>
  );
};

export default ClubSummaryTable;
