import React, { useState } from "react";
import { FaRegHandPeace } from "react-icons/fa";
import { createParrainage } from "./../../services/parrainageServices"; // Importez votre service d'API

export default function Parrainage() {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    nom_parrain: "",
    prenom_parrain: "",
    club_parrain: "",
    email_parrain: "",
    telephone_parrain: "",
    club_parraine: "",
    nom_contact_parraine: "",
    prenom_contact_parraine: "",
    email_parraine: "",
    telephone_parraine: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createParrainage(formData);
      if (response) {
        alert("Parrainage créé avec succès !");
        setFormData({
          nom_parrain: "",
          prenom_parrain: "",
          club_parrain: "",
          email_parrain: "",
          telephone_parrain: "",
          club_parraine: "",
          nom_contact_parraine: "",
          prenom_contact_parraine: "",
          email_parraine: "",
          telephone_parraine: "",
        });
        setShowForm(false);
      }
    } catch (error) {
      alert("Une erreur est survenue lors de la création du parrainage.");
    }
  };

  return (
    <section className="bg-gray-100 dark:bg-neutral-900 text-gray-800 dark:text-gray-200 py-12">
      <div className="container mx-auto flex flex-col lg:flex-row items-center gap-8 px-6">
        {/* Texte de parrainage */}
        <div className="lg:w-1/2 flex flex-col justify-center text-center lg:text-left">
          <h2 className="text-4xl font-extrabold mb-6 text-blue-600">
            Parrainez un club et bénéficiez d'avantages exclusifs !
          </h2>
          <p className="text-lg font-light mb-6">
            Devenez ambassadeur de notre application en parrainant d'autres
            clubs et profitez de :
          </p>
          <ul className="text-lg space-y-4">
            <li>
              <span className="font-semibold text-blue-600">
                5% de réduction
              </span>{" "}
              sur votre prochain abonnement annuel pour chaque club parrainé qui
              souscrit à une offre "Complète" ou "Light".
            </li>
            <li>
              Le club parrainé bénéficie également de cette{" "}
              <span className="font-semibold text-blue-600">remise de 5%</span>{" "}
              sur sa première facture annuelle.
            </li>
            <li>
              <span className="font-semibold text-blue-600">
                10% de réduction
              </span>{" "}
              si vous parrainez plusieurs clubs dans la même saison.
            </li>
          </ul>
          <p className="mt-6 text-gray-700 dark:text-gray-400 font-bold italic">
            Offrez une opportunité unique aux clubs que vous connaissez tout en
            profitant de réductions exclusives sur votre abonnement.
          </p>
        </div>

        {/* Image du parrainage */}
        <div className="lg:w-1/2 w-full h-64 lg:h-auto flex justify-center items-center">
          <img
            src="/images/parrainage_02.png"
            alt="Parrainage de clubs"
            className="w-full h-full object-cover rounded-lg shadow-lg"
          />
        </div>

        </div>

        <button
          className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-all mt-8 mb-6 w-full lg:w-auto mx-auto"
          onClick={() => setShowForm(!showForm)}
        >
          Parrainez un Club
        </button>

        {/* Formulaire de parrainage */}
        {showForm && (
          <form
            className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 grid gap-6 lg:grid-cols-2"
            onSubmit={handleSubmit}
          >
            {/* Partie gauche : Club Parrain */}
            <div>
              <h3 className="text-2xl font-bold mb-4 text-blue-600">
                Club Parrain
              </h3>
              <div className="mb-4">
                <label
                  htmlFor="club_parrain"
                  className="block text-gray-700 dark:text-gray-300"
                >
                  Club Parrain
                </label>
                <input
                  type="text"
                  id="club_parrain"
                  name="club_parrain"
                  value={formData.club_parrain}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="nom_parrain"
                  className="block text-gray-700 dark:text-gray-300"
                >
                  Nom
                </label>
                <input
                  type="text"
                  id="nom_parrain"
                  name="nom_parrain"
                  value={formData.nom_parrain}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="prenom_parrain"
                  className="block text-gray-700 dark:text-gray-300"
                >
                  Prénom
                </label>
                <input
                  type="text"
                  id="prenom_parrain"
                  name="prenom_parrain"
                  value={formData.prenom_parrain}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="email_parrain"
                  className="block text-gray-700 dark:text-gray-300"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email_parrain"
                  name="email_parrain"
                  value={formData.email_parrain}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="telephone_parrain"
                  className="block text-gray-700 dark:text-gray-300"
                >
                  Téléphone
                </label>
                <input
                  type="text"
                  id="telephone_parrain"
                  name="telephone_parrain"
                  value={formData.telephone_parrain}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
            </div>

            {/* Partie droite : Club Parrainé */}
            <div>
              <h3 className="text-2xl font-bold mb-4 text-blue-600">
                Club Parrainé
              </h3>
              <div className="mb-4">
                <label
                  htmlFor="club_parraine"
                  className="block text-gray-700 dark:text-gray-300"
                >
                  Club Parrainé
                </label>
                <input
                  type="text"
                  id="club_parraine"
                  name="club_parraine"
                  value={formData.club_parraine}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="nom_contact_parraine"
                  className="block text-gray-700 dark:text-gray-300"
                >
                  Nom du Contact
                </label>
                <input
                  type="text"
                  id="nom_contact_parraine"
                  name="nom_contact_parraine"
                  value={formData.nom_contact_parraine}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="prenom_contact_parraine"
                  className="block text-gray-700 dark:text-gray-300"
                >
                  Prénom du Contact
                </label>
                <input
                  type="text"
                  id="prenom_contact_parraine"
                  name="prenom_contact_parraine"
                  value={formData.prenom_contact_parraine}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email_parraine"
                  className="block text-gray-700 dark:text-gray-300"
                >
                  Email du Club Parrainé
                </label>
                <input
                  type="email"
                  id="email_parraine"
                  name="email_parraine"
                  value={formData.email_parraine}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="telephone_parraine"
                  className="block text-gray-700 dark:text-gray-300"
                >
                  Téléphone du Club Parrainé
                </label>
                <input
                  type="text"
                  id="telephone_parraine"
                  name="telephone_parraine"
                  value={formData.telephone_parraine}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
            </div>

            {/* Bouton de soumission */}
            <div className="col-span-2 text-center">
              <button
                type="submit"
                className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-all"
              >
                Soumettre le Parrainage
              </button>
            </div>
          </form>
        )}
      
    </section>
  );
}
