import React, { useState, useEffect } from "react";
import Footer from "./../Home/Footer";
import useScrollToTop from "../../hooks/useScrollToTop";
import AuthIcons from "../../hooks/AuthIcons";
import AboutJudoCoachPro from "./AboutJudoCoachPro";
import FormContact from "./../Home/FormContact";
import { FaPhoneAlt, FaHeart } from "react-icons/fa";
import DiaporamaJudoCoachPro from "./DiaporamaJudoCoachPro";

const JudoCoachPro = () => {
  useScrollToTop();

  const [currentImage, setCurrentImage] = useState(
    "https://res.cloudinary.com/dy5kblr32/image/upload/v1728204732/fake/illustration_JCP1.webp"
  );

  useEffect(() => {
    const images = [
      "https://res.cloudinary.com/dy5kblr32/image/upload/v1728204732/fake/illustration_JCP1.webp",
      "https://res.cloudinary.com/dy5kblr32/image/upload/v1728204732/fake/illustration_JCP2.webp",
    ];
    let currentIndex = 0;

    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % images.length;
      setCurrentImage(images[currentIndex]);
    }, 7000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <AuthIcons />
      <div className="flex-grow">
        <div className="relative h-screen bg-gradient-to-br from-blue-900 to-blue-500 text-white">
          {/* Contenu Centré */}
          <div className="flex flex-col items-center justify-center h-full text-center px-4">
            {/* Logo */}
            <img
              src="/images/judo-coach/logo_JCP.png"
              alt="Logo Judo Coach Pro"
              className="w-32 md:w-48 lg:w-64 mb-6"
            />
            {/* Titre */}
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold">
              Judo Coach Pro
            </h1>
            {/* Description */}
            <p className="mt-4 text-lg md:text-xl max-w-3xl">
              Une application web de performances et de révision technique pour
              judokas. Gratuite pour les judokas, avec des formules payantes
              pour les coachs.
            </p>
          </div>
        </div>

        {/* Section About */}
        <div id="about" className="mt-8">
          <AboutJudoCoachPro />
        </div>

        {/* Encart phase de test et dons */}
        <div className="bg-blue-50 dark:bg-gray-800 py-8 px-4 text-center rounded-lg shadow-lg max-w-3xl mx-auto mt-4">
          <h3 className="text-2xl font-bold text-blue-900 dark:text-white">
            Application gratuite pour les judokas
          </h3>
          <p className="text-gray-700 dark:text-gray-300 mt-4">
            L'application Judo Coach Pro est une application web installable sur tous les types d'appareils (Android, iOS, tablettes, PC).
            Si vous souhaitez soutenir le projet et contribuer
            à son amélioration, vous pouvez faire un don.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mt-4">
            Votre générosité m'aidera à développer de nouvelles fonctionnalités
            et à améliorer nos services pour la communauté du judo.
          </p>
          <a
            href="https://buy.stripe.com/fZe8xlaqLgYkdj2fYZ"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center justify-center px-6 py-3 mt-6 bg-blue-500 hover:bg-blue-600 text-white text-lg font-semibold rounded-full shadow-md transition-all"
          >
            <FaHeart className="mr-2" />
            Faire un don de 5 €
          </a>
        </div>

        {/* Image illustrative pleine largeur */}
        <div id="diaporama" className="my-8">
          <img
            src="/images/judo-coach/illustration_01.webp"
            alt="Illustration Judo Coach Pro"
            className="w-full max-h-[600px] object-cover"
          />
        </div>

        {/* Diaporama */}
        <DiaporamaJudoCoachPro />

        {/* Section de contact avec téléphone et formulaire */}
        <div className="bg-gradient-to-r from-blue-400 to-blue-600 text-white text-center py-8">
          <h2 className="text-2xl font-bold">Je souhaite être recontacté</h2>
          <p className="mt-4">
            Vous souhaitez en savoir plus sur notre application Judo Coach Pro ?
            <br />
            Remplissez le formulaire ci-dessous ou appelez-nous directement.
          </p>

          {/* Bouton avec icône de téléphone et numéro à droite */}
          <div className="mt-6 flex justify-center items-center">
            <a
              href="tel:0680727707"
              className="flex items-center bg-white text-black px-4 py-2 rounded-full transition hover:bg-gray-300"
            >
              <FaPhoneAlt className="mr-2" /> {/* Icône de téléphone */}
              Appeler maintenant
            </a>
            <span className="ml-4 text-3xl font-bold">06.80.72.77.07</span>{" "}
            {/* Numéro à droite */}
          </div>
        </div>

        {/* Composant FormContact */}
        <FormContact />
      </div>

      <Footer />
    </div>
  );
};

export default JudoCoachPro;
