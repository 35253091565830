import React, { useState } from "react";
import ComponentTitle from "../Home/ComponentTitle";

export default function Pricing() {
  const [isAnnuel, setIsAnnuel] = useState(false); // Toggle entre Mensuel et Annuel
  const [nbAdherents, setNbAdherents] = useState("<100"); // Par défaut, moins de 100 adhérents

  // Fonction pour basculer entre Mensuel et Annuel
  const toggleTarif = () => {
    setIsAnnuel(!isAnnuel);
  };

  // Fonction pour changer les tarifs selon le nombre d'adhérents
  const handleAdherentsChange = (val) => {
    setNbAdherents(val);
  };

  // Déterminer les tarifs en fonction du nombre d'adhérents
  const tarifsStandard = {
    "<100": { mensuel: 30, annuel: 342 },
    "100-200": { mensuel: 35, annuel: 399 },
    ">200": { mensuel: 40, annuel: 456 },
  };

  const { mensuel, annuel } = tarifsStandard[nbAdherents];

  return (
    <section className=" bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <ComponentTitle title="Nos" highlight=" Abonnements" />
          <p className="mb-5 font-light text-gray-500 dark:text-gray-400 sm:text-xl">
            Choisissez le plan qui vous convient le mieux.
          </p>

          {/* Toggle Mensuel / Annuel */}
          <div className="flex justify-center mb-12">
            <button
              className={`px-6 py-2 mr-2 font-semibold rounded-full transition-all ${
                !isAnnuel ? "bg-blue-600 text-white" : "bg-gray-200 dark:bg-gray-800 dark:text-gray-200 text-black"
              }`}
              onClick={toggleTarif}
            >
              Mensuel
            </button>
            <button
              className={`px-6 py-2 font-semibold rounded-full transition-all ${
                isAnnuel ? "bg-blue-600 text-white" : "bg-gray-200 dark:bg-gray-800 dark:text-gray-200 text-black"
              }`}
              onClick={toggleTarif}
            >
              Annuel
            </button>
          </div>

          {/* Cartes alignées au centre */}
          <div className="flex flex-col lg:flex-row justify-center gap-8 items-center">
            {/* MonAppliClub Light Card */}
            <div className="flex flex-col p-10 text-center bg-purple-100 dark:bg-gray-800 rounded-lg shadow-lg w-96 border border-purple-950 dark:border-gray-600">
              {/* Titre MonAppliClub */}
              <span className="text-5xl font-semibold font-passionOne">
                Mon
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600">
                  Appli
                </span>
                Club
              </span>
              <h3 className="text-2xl font-thin text-gray-900 dark:text-gray-200 uppercase mb-6 mt-2">
                Version Light
              </h3>

              {/* Cercle violet pour le prix */}
              <div className="flex justify-center items-center mb-8">
                <div className="bg-purple-500 text-white rounded-full w-36 h-36 flex items-center justify-center">
                  <span className="text-4xl font-extrabold">
                    {isAnnuel ? "171€" : "15€"}
                  </span>
                  <span className="text-lg">/{isAnnuel ? "an" : "mois"}</span>
                </div>
              </div>

              {/* Liste des avantages */}
              <ul className="mb-8 text-left text-gray-600 dark:text-gray-300 space-y-4">
                <li className="flex items-center">
                  <span className="bg-purple-500 text-white rounded-full w-6 h-6 flex items-center justify-center mr-2">
                    ✔
                  </span>
                  Pour tous les clubs.
                </li>
                <li className="flex items-center">
                  <span className="bg-purple-500 text-white rounded-full w-6 h-6 flex items-center justify-center mr-2">
                    ✔
                  </span>
                  Livrable en moins de 24h.
                </li>
                <li className="flex items-center">
                  <span className="bg-purple-500 text-white rounded-full w-6 h-6 flex items-center justify-center mr-2">
                    ✔
                  </span>
                  Spécifique pour les petits budgets.
                </li>
                <li className="flex items-center">
                  <span className="bg-purple-500 text-white rounded-full w-6 h-6 flex items-center justify-center mr-2">
                    ✔
                  </span>
                  Adaptable à 100%.
                </li>
              </ul>

              {/* Bouton d'appel à l'action */}
              <div className="mt-auto">
                <a
                  href="/tarifs#contact"
                  className="block w-full py-3 text-center text-white bg-purple-500 rounded-lg hover:bg-purple-600 transition-colors"
                >
                  {isAnnuel ? "Informations" : "Informations"}
                </a>
                {isAnnuel && (
                  <p className="mt-3 text-sm text-gray-800 dark:text-gray-400">
                    Économisez 5% en choisissant l'abonnement annuel
                  </p>
                )}
              </div>
            </div>

            {/* MonAppliClub Complète Card */}
            <div className="flex flex-col p-10 text-center bg-blue-100 dark:bg-gray-800 rounded-lg shadow-lg w-96 border border-blue-950 dark:border-gray-600">
              {/* Titre MonAppliClub */}
              <span className="text-5xl font-semibold font-passionOne">
                Mon
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600">
                  Appli
                </span>
                Club
              </span>
              <h3 className="text-2xl font-thin text-gray-900 dark:text-gray-200 uppercase mb-6 mt-2">
                Version Complète
              </h3>

              {/* Cercle bleu pour le prix */}
              <div className="flex justify-center items-center mb-4">
                <div className="bg-blue-500 text-white rounded-full w-36 h-36 flex items-center justify-center">
                  <span className="text-4xl font-extrabold">
                    {isAnnuel ? `${annuel}€` : `${mensuel}€`}
                  </span>
                  <span className="text-lg">/{isAnnuel ? "an" : "mois"}</span>
                </div>
              </div>

              {/* Sélecteur du nombre d'adhérents */}
              <div className="flex flex-col justify-center mb-4 space-x-2">
                <p className="text-gray-900 dark:text-gray-200">
                  Nombre d'adhérents au club
                </p>
                <div className="space-x-2">
                  <button
                    className={`px-3 py-2 rounded-lg transition-all ${
                      nbAdherents === "<100"
                        ? "bg-blue-600 text-white"
                        : "bg-blue-200 dark:bg-gray-800 dark:text-gray-200 text-black"
                    }`}
                    onClick={() => handleAdherentsChange("<100")}
                  >
                    {"<100"}
                  </button>
                  <button
                    className={`px-3 py-2 rounded-lg transition-all ${
                      nbAdherents === "100-200"
                        ? "bg-blue-600 text-white"
                        : "bg-blue-200 dark:bg-gray-800 dark:text-gray-200 text-black"
                    }`}
                    onClick={() => handleAdherentsChange("100-200")}
                  >
                    {"100-200"}
                  </button>
                  <button
                    className={`px-3 py-2 rounded-lg transition-all ${
                      nbAdherents === ">200"
                        ? "bg-blue-600 text-white"
                        : "bg-blue-200 dark:bg-gray-800 dark:text-gray-200 text-black"
                    }`}
                    onClick={() => handleAdherentsChange(">200")}
                  >
                    {">200"}
                  </button>
                </div>
              </div>

              {/* Liste des avantages */}
              <ul className="mb-8 text-left text-gray-600 dark:text-gray-300 space-y-4">
                <li className="flex items-center">
                  <span className="bg-blue-500 text-white rounded-full w-6 h-6 flex items-center justify-center mr-2">
                    ✔
                  </span>
                  Pour les clubs de toutes tailles
                </li>
                <li className="flex items-center">
                  <span className="bg-blue-500 text-white rounded-full w-6 h-6 flex items-center justify-center mr-2">
                    ✔
                  </span>
                  Livrable en moins de 24h.
                </li>
                <li className="flex items-center">
                  <span className="bg-blue-500 text-white rounded-full w-6 h-6 flex items-center justify-center mr-2">
                    ✔
                  </span>
                  Gestion globale du club.
                </li>
                <li className="flex items-center">
                  <span className="bg-blue-500 text-white rounded-full w-6 h-6 flex items-center justify-center mr-2">
                    ✔
                  </span>
                  Paramétrable à 100%.
                </li>
              </ul>

              {/* Bouton d'appel à l'action */}
              <div className="mt-auto">
                <a
                  href="/tarifs#contact"
                  className="block w-full py-3 text-center text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition-colors"
                >
                  {isAnnuel ? "Informations" : "Informations"}
                </a>
                {isAnnuel && (
                  <p className="mt-3 text-sm text-gray-800 dark:text-gray-400">
                    Économisez 5% en choisissant l'abonnement annuel
                  </p>
                )}
              </div>
            </div>

            {/* MonAppliClub Omnisports Card */}
            <div className="flex flex-col p-10 text-center bg-red-100 dark:bg-gray-800 rounded-lg shadow-lg w-96 border border-purple-950 dark:border-gray-600">
              {/* Titre MonAppliClub */}
              <span className="text-5xl font-semibold font-passionOne">
                Mon
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600">
                  Appli
                </span>
                Club
              </span>
              <h3 className="text-2xl font-thin text-gray-900 dark:text-gray-200 uppercase mb-6 mt-2">
                Version Omnisports
              </h3>

              {/* Cercle violet pour le prix */}
              <div className="flex justify-center items-center mb-8">
                <div className="bg-purple-500 text-white rounded-full w-36 h-36 flex items-center justify-center">
                  <span className="text-4xl font-extrabold">
                    {isAnnuel ? "228€" : "20€"}
                  </span>
                  <span className="text-lg">/{isAnnuel ? "an" : "mois"}</span>
                </div>
              </div>

              {/* Liste des avantages */}
              <ul className="mb-8 text-left text-gray-600 dark:text-gray-300 space-y-4">
                <li className="flex items-center">
                  <span className="bg-purple-500 text-white rounded-full w-6 h-6 flex items-center justify-center mr-2">
                    ✔
                  </span>
                  Pour associations omnisports ou Mairies.
                </li>
                <li className="flex items-center">
                  <span className="bg-purple-500 text-white rounded-full w-6 h-6 flex items-center justify-center mr-2">
                    ✔
                  </span>
                  Livrable en moins de 24h.
                </li>
                <li className="flex items-center">
                  <span className="bg-purple-500 text-white rounded-full w-6 h-6 flex items-center justify-center mr-2">
                    ✔
                  </span>
                  Gestion indépendante des sections.
                </li>
                <li className="flex items-center">
                  <span className="bg-purple-500 text-white rounded-full w-6 h-6 flex items-center justify-center mr-2">
                    ✔
                  </span>
                  Adaptable à 100%.
                </li>
              </ul>

              {/* Bouton d'appel à l'action */}
              <div className="mt-auto">
                <a
                  href="/tarifs#contact"
                  className="block w-full py-3 text-center text-white bg-purple-500 rounded-lg hover:bg-purple-600 transition-colors"
                >
                  {isAnnuel ? "Informations" : "Informations"}
                </a>
                {isAnnuel && (
                  <p className="mt-3 text-sm text-gray-800 dark:text-gray-400">
                    Économisez 5% en choisissant l'abonnement annuel
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
