import React, { useState, useEffect } from "react";
import { getAllProspects, addProspect } from "../../../../services/prospectsServices";
import TableProspects from "./TableProspects";
import KanbanProspects from "./KanbanProspects";
import AddProspectModal from "./AddProspectModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProspectsPage = () => {
  const [prospects, setProspects] = useState([]);
  const [viewMode, setViewMode] = useState("table"); // "table" ou "kanban"
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchData = async () => {
    try {
      const prospectsData = await getAllProspects();
      setProspects(prospectsData || []);
    } catch (error) {
      console.error("Erreur lors du chargement des prospects :", error);
      toast.error("Erreur lors du chargement des prospects");
    }
  };

  const handleAddProspect = async (prospect) => {
    try {
      const newProspect = await addProspect(prospect);
      if (newProspect) {
        toast.success("Prospect ajouté avec succès !");
        fetchData(); // Rafraîchir les données
      }
    } catch (error) {
      toast.error("Erreur lors de l'ajout du prospect.");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-light tracking-wide text-gray-800 dark:text-white mb-4">
        Gestion des Prospects
      </h1>

      <div className="flex justify-between mb-4">
        <div>
          <button
            className={`px-4 py-2 rounded-lg ${
              viewMode === "table"
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-800"
            }`}
            onClick={() => setViewMode("table")}
          >
            Vue Tableau
          </button>
          <button
            className={`ml-2 px-4 py-2 rounded-lg ${
              viewMode === "kanban"
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-800"
            }`}
            onClick={() => setViewMode("kanban")}
          >
            Vue Kanban
          </button>
        </div>
        <button
          className="px-4 py-2 bg-green-500 text-white rounded-lg"
          onClick={() => setIsModalOpen(true)}
        >
          Ajouter un Prospect
        </button>
      </div>

      {viewMode === "table" ? (
        <TableProspects prospects={prospects} />
      ) : (
        <KanbanProspects prospects={prospects} />
      )}

      <AddProspectModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAdd={handleAddProspect}
      />

      <ToastContainer />
    </div>
  );
};

export default ProspectsPage;
