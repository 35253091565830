import React from "react";
import ComponentTitle from "../Home/ComponentTitle";
import useScrollToTop from "../../hooks/useScrollToTop";

const AboutJudoCoachPro = () => {
  useScrollToTop();

  return (
    <section
      id="about"
      className="py-16 bg-gray-100 dark:bg-neutral-900 text-gray-800 dark:text-gray-200"
    >
      <div className="container mx-auto px-4">
        {/* Titre principal */}
        <div className="mx-auto max-w-screen-md text-center mb-12">
          <ComponentTitle title="Découvrez" highlight=" les Formules" />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Judo Expert */}
          <div className="bg-slate-200 dark:bg-neutral-800 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-4 bg-green-400 dark:bg-green-600 p-2 rounded-md shadow-md text-center">
              🥋 Judo Expert
            </h3>
            <p className="text-center text-green-600 dark:text-green-400 font-semibold mb-4 text-2xl">
              Gratuit
            </p>
            <ul className="list-none">
              <li className="mb-4">✔️ Accès à la partie performances :</li>
              <li className="mb-4">
                &nbsp;&nbsp;- Résultats, palmarès, passage de grades et
                statistiques
              </li>
              <li className="mb-4">
                ✔️ Accès à vos compétitions et évènements partagés
              </li>
              <li className="mb-4">✔️ Accès à la partie techniques :</li>
              <li className="mb-4">&nbsp;&nbsp;- UV2, kata, Quizz, Lexique</li>
              <li className="mb-4">✔️ Accès à la partie arbitrage :</li>
              <li className="mb-4">
                &nbsp;&nbsp;- Règlement, Quizz, Tableau de score
              </li>
            </ul>
          </div>

          {/* Coach */}
          <div className="bg-slate-200 dark:bg-neutral-800 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-4 bg-blue-200 dark:bg-blue-500 p-2 rounded-md shadow-md text-center">
              🥋 Coach
            </h3>
            <p className="text-center text-blue-600 dark:text-blue-400 font-semibold mb-4 text-2xl">
              10 €
            </p>
            <ul className="list-none">
              <li className="mb-4">
                ✔️ Toutes les fonctionnalités de Judo Expert
              </li>
              <li className="mb-4">
                ✔️ Gestion d'un groupe jusqu'à 10 adhérents
              </li>
              <li className="mb-4">
                ✔️ Messagerie privée et sécurisée avec vos adhérents
              </li>
              <li className="mb-4">
                ✔️ Invitation et réception des réponses à vos évènements
              </li>
              <li className="mb-4">
                ✔️ Résultats, palmarès et statistiques de vos adhérents
              </li>
            </ul>
          </div>

          {/* Coach Pro */}
          <div className="bg-slate-200 dark:bg-neutral-800 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-4 bg-blue-300 dark:bg-blue-500 p-2 rounded-md shadow-md text-center">
              🥋 Coach Pro
            </h3>
            <p className="text-center text-blue-600 dark:text-blue-400 font-semibold mb-4 text-2xl">
              20 €
            </p>
            <ul className="list-none">
              <li className="mb-4">✔️ Toutes les fonctionnalités de Coach</li>
              <li className="mb-4">
                ✔️ Gestion de 5 groupes, jusqu'à 50 adhérents
              </li>
            </ul>
          </div>

          {/* Coach Ultim */}
          <div className="bg-slate-200 dark:bg-neutral-800 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-4 bg-blue-400 dark:bg-blue-500 p-2 rounded-md shadow-md text-center">
              🥋 Coach Ultim
            </h3>
            <p className="text-center text-blue-600 dark:text-blue-400 font-semibold mb-4 text-2xl">
              30 €
            </p>
            <ul className="list-none">
              <li className="mb-4">
                ✔️ Toutes les fonctionnalités de Coach Pro
              </li>
              <li className="mb-4">
                ✔️ Gestion de 10 groupes, jusqu'à +50 adhérents
              </li>
              <li className="mb-4">
                ✔️ Notifications Push pour vos évènements
              </li>
            </ul>
          </div>
        </div>

        {/* Appel à l'action */}
        <div className="mt-12 text-center mx-auto">
          <button
            onClick={() =>
              window.open("https://www.judo-coach-pro.com/", "_blank")
            }
            className="px-8 py-3 bg-gradient-to-r from-yellow-400 to-orange-600 text-white rounded-full transition relative group w-96"
          >
            <span className="group-hover:opacity-0 transition-opacity duration-300">
              Découvrir Judo Coach Pro
            </span>
            <span className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 font-passionOne text-3xl font-light">
              Judo Coach Pro
            </span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default AboutJudoCoachPro;
