import React, { useState } from "react";
import ComponentTitle from "../Home/ComponentTitle";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const images = [
  {
    src: "/images/judo-coach/001.webp",
    alt: "Menu",
    caption: "Menu de l'Application",
  },
  {
    src: "/images/judo-coach/015.webp",
    alt: "Formules",
    caption: "Formules gratuites et payantes",
  },
  {
    src: "/images/judo-coach/003.webp",
    alt: "Evénements",
    caption: "Consulter ses prochaines compétitions ou échéances",
  },
  {
    src: "/images/judo-coach/004.webp",
    alt: "Evénements partagés",
    caption:
      "Possibilité de consultés les événements partagés par d'autres utilisateurs et de les copier dans son agenda",
  },
  {
    src: "/images/judo-coach/005.webp",
    alt: "Sélection par département",
    caption: "Tri par département sur une carte interactive",
  },
  {
    src: "/images/judo-coach/006.webp",
    alt: "Résultats",
    caption: "Enregistrement de ses résultats sur les événements",
  },
  {
    src: "/images/judo-coach/007.webp",
    alt: "Palmarès",
    caption: "Ajout des résultats sur son palmarès",
  },
  {
    src: "/images/judo-coach/008.webp",
    alt: "Passage de grades",
    caption: "Gestion des passages de grades & UV",
  },
  {
    src: "/images/judo-coach/009.webp",
    alt: "Statistiques",
    caption: "Page de statistiques de ses combats",
  },
  {
    src: "/images/judo-coach/010.webp",
    alt: "Techniques",
    caption: "Révision des techniques de Judo et Ju-Jitsu",
  },
  {
    src: "/images/judo-coach/011.webp",
    alt: "Kata",
    caption: "Révision des kata",
  },
  {
    src: "/images/judo-coach/012.webp",
    alt: "Quizz",
    caption: "Quizz Techniques",
  },
  {
    src: "/images/judo-coach/013.webp",
    alt: "Lexique",
    caption: "Lexique Japonais-Français",
  },
  {
    src: "/images/judo-coach/014.webp",
    alt: "Arbitrage",
    caption: "Règlement d'arbitrage et commissaire sportif",
  },
  {
    src: "/images/judo-coach/016.webp",
    alt: "Coach - Gestion des groupes et judokas",
    caption: "Gestion des groupes et des judokas pour les coachs",
  },
  {
    src: "/images/judo-coach/017.webp",
    alt: "Invitatiton par QR code",
    caption: "Invitation par QR code pour rejoindre un groupe",
  },
  {
    src: "/images/judo-coach/018.webp",
    alt: "Enregistrement des résultats",
    caption: "Enregistrement des résultats des judokas",
  },
  {
    src: "/images/judo-coach/019.webp",
    alt: "Enregistrement des passages de grades",
    caption: "Enregistrement des pasages de grade des judokas",
  },
];

const DiaporamaLight = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const { src, alt, caption } = images[currentImageIndex];

  return (
    <div className="relative py-16 bg-base-100 dark:bg-neutral-900 text-gray-800 dark:text-gray-200">
      <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
        <ComponentTitle title="Quelques" highlight=" Pages" />
        <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
          Voici une sélection de quelques pages de l'application.
        </p>
      </div>

      {/* Carrousel */}
      <div className="relative flex flex-col items-center justify-center">
        {/* Bouton pour image précédente */}
        <button
          className="absolute left-4 md:left-8 bg-orange-400 dark:bg-orange-600 text-white p-3 rounded-full focus:outline-none z-10"
          onClick={handlePrev}
        >
          <FaArrowLeft className="text-xl" />
        </button>

        {/* Conteneur d'image centré */}
        <div className="w-full mx-auto flex justify-center">
          <img
            src={src}
            alt={alt}
            className="w-full max-w-[90%] sm:max-w-[600px] md:max-w-[800px] lg:max-w-[1000px] h-auto rounded-lg object-cover shadow-lg"
          />
        </div>

        {/* Légende sous l'image */}
        <p className="text-center text-gray-600 dark:text-gray-400 mt-4 font-medium">
          {caption}
        </p>

        {/* Bouton pour image suivante */}
        <button
          className="absolute right-4 md:right-8 bg-orange-400 dark:bg-orange-600 text-white p-3 rounded-full focus:outline-none z-10"
          onClick={handleNext}
        >
          <FaArrowRight className="text-xl" />
        </button>
      </div>
    </div>
  );
};

export default DiaporamaLight;
