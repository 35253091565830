import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/prospects`;
const token = localStorage.getItem("token");

// GET
// Route pour récupérer tous les prospects
export const getAllProspects = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allProspects`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des prospects:", error);
    return [];
  }
};

// Route pour récupérer un prospect par son id
export const getProspectById = async (prospectId) => {
  try {
    const response = await axios.get(`${apiUrl}/${prospectId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération du prospect:", error);
    return null;
  }
};

// Route pour récupérer les prospects par colonne Kanban
export const getProspectsByKanbanColumn = async (kanbanColumn) => {
  try {
    const response = await axios.get(`${apiUrl}/kanban/${kanbanColumn}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des prospects:", error);
    return [];
  }
};

// POST
// Route pour ajouter un prospect
export const addProspect = async (prospect) => {
  try {
    const response = await axios.post(`${apiUrl}/addProspect`, prospect, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout du prospect:", error);
    return null;
  }
};

// PATCH
// Route pour modifier un prospect
export const updateProspect = async (id, prospectData) => {
  try {
    const response = await axios.patch(
      `${apiUrl}/updateProspect/${id}`,
      prospectData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification du prospect:", error);
    return null;
  }
};

// Route pour changer la colonne Kanban d'un prospect
export const changeKanbanColumn = async (id, kanbanColumn) => {
  try {
    const response = await axios.patch(
      `${apiUrl}/changeKanbanColumn/${id}`,
      { kanbanColumn },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors du changement de colonne Kanban:", error);
    return null;
  }
};

// DELETE
// Route pour supprimer un prospect
export const deleteProspect = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteProspect/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression du prospect:", error);
    return null;
  }
};
