import React from "react";
import StatisticsCards from "./StatisticsCards";
import ComponentTitle from "./../../../Home/ComponentTitle";
import StatisticClubsCards from "./StatisticClubsCards";
import PagesVisitesBarChart from "./PagesVisitesBarChart";
import ClubSummaryCards from "./ClubSummaryCards";
import RequestsTable from "./RequestsTable";

const DashboardAdmin = () => {
  return (
    <div className="container mx-auto p-4">
      <div className="mx-auto max-w-screen-md text-center mb-4 lg:mb-8 bg-slate-200 rounded-lg p-2">
        <ComponentTitle title="Tableau" highlight=" de Bord" />
        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
          Bienvenue dans votre espace de tableau de bord. Vous pouvez voir les
          statistiques de vos projets ici !
        </p>
      </div>
      <StatisticsCards />
      <div className="mt-4">
        <StatisticClubsCards />
      </div>

      <div className="mt-4">
        <ClubSummaryCards />
      </div>

      <div className="mt-4">
        <RequestsTable />
      </div>

      <div className="mt-4">
        <PagesVisitesBarChart />
      </div>
    </div>
  );
};

export default DashboardAdmin;
