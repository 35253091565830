import React from "react";

const TableProspects = ({ prospects }) => {
  return (
    <div className="overflow-x-auto rounded-lg bg-gray-800 dark:bg-gray-900 p-4">
      <table className="w-full text-left text-sm text-gray-400">
        <thead className="border-b border-gray-700">
          <tr>
            <th className="pb-3 px-4 text-gray-400">Club</th>
            <th className="pb-3 px-4 text-gray-400">Nom</th>
            <th className="pb-3 px-4 text-gray-400">Prénom</th>
            <th className="pb-3 px-4 text-gray-400">Email</th>
            <th className="pb-3 px-4 text-gray-400">Téléphone</th>
            <th className="pb-3 px-4 text-gray-400">Provenance</th>
            <th className="pb-3 px-4 text-gray-400">Statut</th>
          </tr>
        </thead>
        <tbody>
          {prospects.map((prospect) => (
            <tr key={prospect.id} className="border-b border-gray-700 text-gray-100">
              <td className="py-2 px-4">{prospect.club || "N/A"}</td>
              <td className="py-2 px-4">{prospect.nom}</td>
              <td className="py-2 px-4">{prospect.prenom}</td>
              <td className="py-2 px-4">{prospect.email}</td>
              <td className="py-2 px-4">{prospect.telephone || "N/A"}</td>
              <td className="py-2 px-4">{prospect.provenance}</td>
              <td className="py-2 px-4">{prospect.statut}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableProspects;
