import OneSignal from "react-onesignal";

let isInitialized = false; // Suivi de l'état d'initialisation

const initOneSignal = async () => {
  if (!isInitialized) {
    await OneSignal.init({
      appId: "VOTRE_APP_ID_ONESIGNAL", // Remplacez par votre App ID
    });
    isInitialized = true; // Marquer comme initialisé
    console.log("OneSignal SDK initialisé !");
  } else {
    console.warn("OneSignal SDK est déjà initialisé.");
  }
};

export default initOneSignal;
