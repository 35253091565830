import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";

// Liste des clubs avec leurs API
const clubs = [
  { name: "MonAppliClub", apiUrl: "https://api.monappliclub.com" },
  { name: "MonAppliClub Démo", apiUrl: "https://api.monappliclub-demo.com" },
  {
    name: "MonAppliClub Light",
    apiUrl: "https://api.monappliclub-demo-light.com",
  },
  { name: "Judo Coach Pro", apiUrl: "https://api.judo-coach-pro.com" },
  { name: "USB Judo", apiUrl: "https://api.usbouscat-judo.com" },
  { name: "ESSHA Judo Segré", apiUrl: "https://api.essha-judo-segre.com" },
  { name: "Dojo Gujanais", apiUrl: "https://api.dojogujanais.fr" },
  {
    name: "Judo Club Salon-de-Provence",
    apiUrl: "https://api.judoclubsalon.fr",
  },
  { name: "USB Karaté", apiUrl: "https://api.usbouscat-karate.com" },
  { name: "ASSM Judo", apiUrl: "https://api.assm-judo.com/" },

  // Ajoutez les autres clubs ici
];

const VisitesLineChart = () => {
  const [selectedClub, setSelectedClub] = useState(clubs[0].apiUrl); // Club sélectionné par défaut
  const [data, setData] = useState([]);

  // Fonction pour récupérer les visites du club sélectionné
  const fetchVisitesByDate = async (clubApiUrl) => {
    try {
      const response = await axios.get(
        `${clubApiUrl}/api/visites/countVisitesByDate`
      );

      // Mapper les données pour renommer `compteur` en `count`
      const formattedData = response.data.map((item) => ({
        date_visite: item.date_visite,
        count: item.compteur,
      }));

      setData(formattedData);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des données de visites",
        error
      );
      setData([]); // Remise à zéro en cas d'erreur
    }
  };

  // Appel de la fonction lorsque le club sélectionné change
  useEffect(() => {
    fetchVisitesByDate(selectedClub);
  }, [selectedClub]);

  // Gère le changement de club dans le select
  const handleClubChange = (event) => {
    setSelectedClub(event.target.value);
  };

  return (
    <div className="bg-slate-200 rounded-lg shadow-lg p-4 dark:bg-gray-900 dark:text-gray-200">
      <h3 className="text-lg font-bold text-gray-800 mb-4">
        Évolution des Visites
      </h3>

      {/* Select pour choisir le club */}
      <div className="mb-4">
        <label htmlFor="club-select" className="text-gray-800 mr-2">
          Sélectionnez un club :
        </label>
        <select
          id="club-select"
          value={selectedClub}
          onChange={handleClubChange}
          className="p-2 bg-slate-300 text-gray-800 rounded"
        >
          {clubs.map((club) => (
            <option key={club.apiUrl} value={club.apiUrl}>
              {club.name}
            </option>
          ))}
        </select>
      </div>

      {/* Graphique des visites */}
      <div style={{ position: "relative", zIndex: 0 }}>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date_visite" />
            <YAxis
              domain={[0, "dataMax + 50"]} // Fixe la limite supérieure à 50 au-dessus de la valeur max
              allowDataOverflow={true} // Permet d'afficher les valeurs au-delà de la plage initiale
            />

            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="count"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default VisitesLineChart;
