import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/requests`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer toutes les demandes : getAllRequests
export const getAllRequests = async () => {
  try {
    const response = await axios.get(`${apiUrl}/AllRequests`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data) {
      console.log("Réponse API :", response.data);
      return response.data; // ou response.data.data si votre API encapsule les données
    } else {
      console.error("Les données de l'API sont vides ou non définies.");
      return [];
    }
  } catch (error) {
    console.error("Erreur lors du chargement des demandes:", error);
    return []; // Renvoie un tableau vide en cas d'erreur
  }
};

// Fonction pour compter le nombre de demandes : countRequests
export const countRequests = async () => {
  try {
    const response = await axios.get(`${apiUrl}/CountRequests`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération du nombre de demandes:",
      error
    );
  }
};

// Fonction pour récupérer toutes les demandes d'une association : getRequestsByAssociationId
export const getRequestsByAssociationId = async (id) => {
  try {
    const response = await axios.get(
      `${apiUrl}/RequestsByAssociationId/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data) {
      console.log("Réponse API :", response.data);
      return response.data; // ou response.data.data si votre API encapsule les données
    } else {
      console.error("Les données de l'API sont vides ou non définies.");
      return [];
    }
  } catch (error) {
    console.error(
      "Erreur lors du chargement des demandes de l'association:",
      error
    );
    return []; // Renvoie un tableau vide en cas d'erreur
  }
};

// Fonction pour récupérer une demande par son id : getRequestById
export const getRequestById = async (id) => {
  try {
    const response = await axios.get(`${apiUrl}/RequestById/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération de la demande:", error);
  }
};

/// POST
// Fonction pour ajouter une demande : addRequest
export const addRequest = async (request) => {
  try {
    const response = await axios.post(`${apiUrl}/addRequest`, request, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout de la demande:", error);
  }
};

/// PATCH
// Fonction pour modifier une demande par son id : updateRequest
export const updateRequest = async (id, request) => {
  try {
    const response = await axios.patch(
      `${apiUrl}/updateRequest/${id}`,
      request,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification de la demande:", error);
  }
};

/// DELETE
// Fonction pour supprimer une demande par son id : deleteRequest
export const deleteRequest = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteRequest/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de la demande:", error);
  }
};
