import React, { useState } from "react";

const AddProspectModal = ({ isOpen, onClose, onAdd }) => {
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    email: "",
    telephone: "",
    club: "",
    notes: "",
    provenance: "autre",
    statut: "Nouveau Prospect",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAdd(formData);
    onClose();
    setFormData({
      nom: "",
      prenom: "",
      email: "",
      telephone: "",
      club: "",
      notes: "",
      provenance: "autre",
      statut: "Nouveau Prospect",
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-bold mb-4">Ajouter un Prospect</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            name="nom"
            placeholder="Nom"
            value={formData.nom}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          />
          <input
            type="text"
            name="prenom"
            placeholder="Prénom"
            value={formData.prenom}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          />
          <input
            type="text"
            name="telephone"
            placeholder="Téléphone"
            value={formData.telephone}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
          <input
            type="text"
            name="club"
            placeholder="Club"
            value={formData.club}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
          <textarea
            name="notes"
            placeholder="Notes"
            value={formData.notes}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          ></textarea>
          <select
            name="provenance"
            value={formData.provenance}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          >
            <option value="mailing">Mailing</option>
            <option value="phoning">Phoning</option>
            <option value="linkedin">LinkedIn</option>
            <option value="facebook">Facebook</option>
            <option value="connaissance">Connaissance</option>
            <option value="parrainage">Parrainage</option>
            <option value="autre">Autre</option>
          </select>
          <select
            name="statut"
            value={formData.statut}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          >
            <option value="Nouveau Prospect">Nouveau Prospect</option>
            <option value="Contacté & en attente">Contacté & en attente</option>
            <option value="Prospect Qualifié">Prospect Qualifié</option>
            <option value="En Test">En Test</option>
            <option value="Client">Client</option>
            <option value="Perdu">Perdu</option>
          </select>
          <button
            type="submit"
            className="w-full bg-green-500 text-white p-2 rounded"
          >
            Ajouter
          </button>
          <button
            type="button"
            className="w-full mt-2 bg-gray-500 text-white p-2 rounded"
            onClick={onClose}
          >
            Annuler
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddProspectModal;
